define("discourse/plugins/diabeteswiser-high-engagement-device-posts/discourse/helpers/readable-date", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.readableDate = readableDate;
  function readableDate(_ref) {
    let [isoDate] = _ref;
    if (!isoDate) return " - ";

    // Create a new Date object
    const date = new Date(isoDate);

    // Get year, month, and day
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-based, so we add 1
    let day = date.getDate().toString().padStart(2, "0"); // pad with leading zero if necessary

    // Format as Year/Month/Day
    return `${year}/${month}/${day}`;
  }
  var _default = _exports.default = (0, _helper.helper)(readableDate);
});