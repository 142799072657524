define("discourse/plugins/diabeteswiser-high-engagement-device-posts/discourse/helpers/readable-label", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.readableLabel = readableLabel;
  function readableLabel(_ref) {
    let [columnName] = _ref;
    if (!columnName) return "";

    // Split the string by underscores, capitalize each part, and join with spaces
    return columnName.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ").replace("Id", "ID").replace("Url", "URL");
  }
  var _default = _exports.default = (0, _helper.helper)(readableLabel);
});